<!-- 统一身份认证登录临时页面 -->
<template>
  <div />
  <!-- <van-overlay :show="show"> -->
  <!-- <div class="wrapper" @click.stop>
      {{ errorMessage }}
    </div> -->
  <!-- </van-overlay> -->
</template>
<script>
import { Overlay } from 'vant'
import { Toast } from 'vant'
export default {
  name: 'TransferError',
  components: {
    [Overlay.name]: Overlay,
    [Toast.name]: Toast
  },
  data() {
    return {
      show: true,
      errorMessage: '系统错误',
      toast: ''
    }
  },
  mounted() {
    this.showErrorMessage()
    this.toast = Toast.fail({
      duration: 1500,
      message: this.errorMessage
    })
    this.time = setInterval(() => {
      this.$router.push({ path: '/' })
    }, 1000)
  },
  destroyed() {
    this.toast.clear()
  },
  methods: {
    showErrorMessage() {
      // Toast.fail(this.errorMessage)
      if (this.$route.query.errorMsg) {
        this.errorMessage = this.$route.query.errorMsg
      }
    }
  }
}
</script>
<style scoped lang="scss">
.van-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper{
  width: 200px;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
}
</style>
